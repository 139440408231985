import { ethers } from 'ethers'
import { type IHODLStakeInfo } from './hodl-stake-info'

export const useHODLStakeDialogStore = defineStore('hodlStakeDialog', () => {
  const DEFAULT_DATA: IHODLStakeInfo = {
    address: ethers.ZeroAddress,
    apr: '0',
    token: 'uHODL',
    staked: {
      amount: '0',
      amountUSD: '0',
    },
    tvl: {
      amount: '0',
      amountUSD: '0',
    },
  }
  const {
    value: isDialogVisible,
    setTrue: openDialog,
    setFalse: closeDialog,
  } = useBoolean(false)
  const data = ref<IHODLStakeInfo>(DEFAULT_DATA)

  const setData = (newData: IHODLStakeInfo) => {
    data.value = newData
  }

  const reset = () => {
    data.value = DEFAULT_DATA
  }

  return {
    data,
    isDialogVisible,
    openDialog,
    closeDialog,
    setData,
    reset,
  }
})
